import axios from 'axios';

const base_url = process.env.REACT_APP_LOCAL_URL;
const reviewUrl = process.env.REACT_APP_BASE_URL;
const base_url1 = process.env.REACT_APP_CRM_URL;
const uname = process.env.API_AUTH_USERNAME;
export const Axios = axios.create({
  auth: {
    username: process.env.API_AUTH_USERNAME,
    password: process.env.API_AUTH_PASSWORD,
  },
});

// non portal url
export const GetAllReviewsInfo = () => {
  return axios.get(`${reviewUrl}WarrantyPlan/getReviewList`);
};

export const SendIpOnHomeLoad = (data) => {
  console.log('data', data);
  return axios.post(
    `${base_url1}/traffictracking/add?ip=${encodeURIComponent(data?.ip)}`,
    data,
    {
      auth: {
        username: 'prasad',
        password: 'prasad',
      },
    }
  );
};

export const GetCarPortalLandingSummary = () => {
  return axios.get(`${base_url}api/v1/getcarssummary`);
};

// export const GetAllCarsList = (numCars = 0) => {
//   return axios.get(`${base_url}api/v1/getlatestlistedcars/${numCars}`);
// };

export const SendViewSellerOtp = (data) => {
  return axios.post(`${base_url}api/v1/sendotp`, data);
};

export const VerifyViewSellerOtp = (data) => {
  return axios.post(`${base_url}api/v1/validateotp`, data);
};

export const GetFilters = () => {
  return axios.get(`${base_url}api/v1/getfilters`);
};

export const GetSimilarCars = (data, vid) => {
  return axios.post(`${base_url}api/v1/filteredcars/${vid}`, data);
};

export const GetWarrantyData = () => {
  return axios.get(`${base_url}api/v1/productdetails`);
};

export const GetLinkListedCars = (linkId) => {
  // return axios.get(`${base_url}api/v1/search-cars/${linkId}`);
  return axios.get(`${base_url}api/v1/search-cars/${linkId}`);
};

export const GetDynamicData = () => {
  return axios.get(`${base_url}api/v1/dynamicdata`);
};

export const GetDealerInfo = (vehicleId, phone) => {
  return axios.get(`${base_url}api/v1/dealerInfo/${vehicleId}/${phone}`);
};

export const SendShortListedVehicle = (data) => {
  return axios.post(`${base_url}api/v1/selectedcars`, data);
};

export const SendUnShortListedVehicle = (data) => {
  return axios.post(`${base_url}api/v1/unselectedcars`, data);
};
