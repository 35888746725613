import React, { useState } from 'react';
import { Box, Button, Divider, Link, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/joy/CircularProgress';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// import CircularProgress from "@mui/material/CircularProgress";
import './SampleReport.css';
import WiseDriveLogoNew from '../../assets/images/header/wiseDrivelogoNew.png';
import FairCarLogo from '../../assets/images/header/FairCarLogo.png';
import SampleImg from '../../assets/images/sampleReport/sampleImg.webp';
import LeftQuote from '../../assets/images/sampleReport/left-quote.svg';
import RightQuote from '../../assets/images/sampleReport/right-quote.svg';
import Si1 from './si1.webp';
import {
  vechileInfoData,
  vechileRatingGuideData,
  vechilReportDataSample,
  vechilReportData,
} from './SampleReportConstants';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#70BF44',
  },
}));

function SampleReport() {
  const [selectedMenu, setSelectedMenu] = useState(
    vechilReportData?.[0]?.header
  );
  const [isExpand, setIsExpand] = useState(false);
  const scrollToTestDiv = (ele) => {
    const divElement = document.getElementById(ele);
    divElement.scrollIntoView({ behavior: 'smooth' });
  };
  const handleMenuClick = (ele) => {
    setSelectedMenu(ele);
    scrollToTestDiv(ele);
  };
  return (
    <div className="sample-report-container">
      <div className="sample-report-header">
        <div className="sample-report-content">
          <p className="sample-report-refference"> Reference no. 22462653</p>
          <Link
            href="https://testwarrantycars.wisedrive.in/"
            style={{ paddingTop: '90px' }}
          >
            <span className="image-span-1">
              <span className="image-span-2">
                <img
                  src={WiseDriveLogoNew}
                  style={{
                    display: 'block',
                    height: 'initial',
                    background: 'none',
                    opacity: '1',
                    border: '0',
                    padding: '0',
                    width: '250px !important',
                    marginLeft: '-5px !important',
                  }}
                  href="https://testwarrantycars.wisedrive.in/"
                  alt=""
                  aria-hidden="true"
                />
              </span>
            </span>
          </Link>
        </div>
        <div className="sample-report-description">
          <p>PRE-PURCHASE</p>
          <h1>Inspection Report</h1>
          <span>Requested By : Customer</span>
          <span>Requested On : 11 Jul 2024, 11:16 AM</span>
          <span>Completed on : 11 Jul 2024, 11:16 AM</span>
        </div>
      </div>
      <div className="sample-report-body">
        <div className="sample-report-body-container">
          <div className="sample-report-body-header">
            <h1>2015 MERCEDES-BENZ CLS-CLASS</h1>
            <Divider />
            <div className="sample-report-vechileinfo">
              <div className="vechileinfo-left">
                <span className="vechileinfo-left-span-image">
                  <span className="vechileinfo-left-span-image-1"></span>
                  <img
                    alt="Inspection photos"
                    className="inspection-photos"
                    sizes="100vw"
                    // srcset="/_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=640&amp;q=75 640w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=750&amp;q=75 750w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=828&amp;q=75 828w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=1080&amp;q=75 1080w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=1200&amp;q=75 1200w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=1920&amp;q=75 1920w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=2048&amp;q=75 2048w, /_next/image?url=https%3A%2F%2Fcarinspect-prod-user-media-public.s3.ap-southeast-2.amazonaws.com%2F36fd78b0-b73a-4089-a1f1-6d803bf615d2_thumb.jpg&amp;w=3840&amp;q=75 3840w"
                    src={SampleImg}
                    decoding="async"
                    data-nimg="responsive"
                  />
                  <div className="image-count-capsule">+2 Images</div>
                </span>
              </div>
              <div className="vechileinfo-right">
                {vechileInfoData?.map((ele, index) => {
                  return (
                    <div key={index} className="vechileinfo-container">
                      <div className="vechileInfo-label">{ele.label}</div>
                      <div className="vechileInfo-value">{ele.value}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider />
            <div className="vechile-rating-guide">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <h1>Inspection Summary</h1>
              </div>
              <div className="vechile-rating-guide-container">
                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">Overall Rating</div>
                  <div className="vechile-rating-value">
                    <Rating name="customized-10" defaultValue={9} max={10} />
                  </div>
                </div>
                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">Recommend to buy</div>
                  <div
                    className="vechile-rating-value"
                    style={{
                      fontFamily: 'matterBold',
                      // fontSize: "16px",
                      color: 'green',
                    }}
                  >
                    YES
                  </div>
                </div>
                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">
                    Recommended Purchase Price
                  </div>
                  <div
                    className="vechile-rating-value"
                    style={{
                      fontFamily: 'matterBold',
                      fontSize: '16px',
                    }}
                  >
                    Rs 10,40,000
                  </div>
                </div>
                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">Repair Estimate</div>
                  <div
                    className="vechile-rating-value"
                    style={{
                      fontFamily: 'matterBold',
                      fontSize: '16px',
                      color: 'red',
                    }}
                  >
                    Rs 35,000
                  </div>
                </div>
                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">Engine Warranty</div>
                  <div
                    className="vechile-rating-value"
                    style={{
                      display: 'flex',
                      fontFamily: 'matterBold',
                      color: 'green',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    6 Months
                    <Button variant="contained">Upgrade to 12 Months</Button>
                    <Button variant="contained">Upgrade to 2 Years</Button>
                  </div>
                </div>

                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">
                    Insurance 3rd Party
                  </div>
                  <div
                    className="vechile-rating-value"
                    style={{
                      display: 'flex',
                      fontFamily: 'matterBold',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    Rs 2890 <Button variant="contained">Buy Now</Button>
                  </div>
                </div>
                <div className="vechile-rating-guide-content">
                  <div className="vechile-rating-label">
                    Insurance OD Premium
                    <br />
                    (IDV value Rs{' '}
                    <Link onClick={() => alert('modal with enter idv value')}>
                      5,50,000
                    </Link>
                    )
                  </div>
                  <div
                    className="vechile-rating-value"
                    style={{
                      display: 'flex',
                      fontFamily: 'matterBold',
                      flexDirection: 'row',
                      gap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    Rs 6750 <Button variant="contained">Buy Now</Button>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <div className="sample-report-mechanic-notes">
              <div className="mechanic-notes-left-quotes">
                <span className="left-quotes">
                  <span className="left-quotes-1">
                    <img
                      className="left-quote-css"
                      alt=""
                      aria-hidden="true"
                      src={LeftQuote}
                    />
                  </span>
                  {/* <img
                    alt="left quote"
                    src={LeftQuote}
                    decoding="async"
                    data-nimg="intrinsic"
                    className="left-quote-css"
                  />
                  <img
                    alt="left quote"
                    src={LeftQuote}
                    decoding="async"
                    data-nimg="intrinsic"
                    className="left-quote-css"
                    loading="lazy"
                  /> */}
                </span>
              </div>
              <div className="mechanic-notes-content">
                <h1>Notes from the mechanic</h1>
                <p>
                  Road test was good, power and performance was good also. Log
                  book history is up to date and stamped in back of book. Spare
                  keys also. Interior of vehicle is in good condition no major
                  stains or marks. As pictured. Front and rear seats in good
                  condition. Would just advise getting vehicle detailed.
                  Exterior of vehicle is in good condition no major accident
                  damage or marks. Just in a unclean condition been sitting for
                  a few weeks in the garage. Being a black car easier to spot
                  smaller scratches and marks. There were a few minor scratch
                  marks around the rear boot area. But overall in good condition
                  just would advise also a good detail and clean and polish to
                  get minor scratches and marks out.
                </p>
              </div>
              <div className="mechanic-notes-right-quotes">
                <span className="right-quotes">
                  <span className="right-quotes-1">
                    <img
                      className="right-quote-css"
                      alt=""
                      aria-hidden="true"
                      src={RightQuote}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="inspection-report-container">
              {vechilReportData?.map((ele, index) => {
                return (
                  <div key={index} className="report-type-section">
                    <h1>{ele.header}</h1>
                    <CircularProgress
                      determinate
                      value={ele.Value}
                      sx={{
                        '--CircularProgress-size': '180px',
                        '--_track-thickness': '15px',
                        '--_progress-thickness': '15px',
                        '--CircularProgress-progressColor': '#70BF44',
                      }}
                    >
                      <div className="report-value-container">
                        <span> {ele.Value}</span>
                        <br />
                        out of 100
                      </div>
                    </CircularProgress>
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className="inspection-report-details-container">
              <div className="inspection-report-details-header">
                <div className="inspection-report-details-header-container">
                  <div className="inspection-report-details-menu">
                    {vechilReportData?.map((ele, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => handleMenuClick(ele?.header)}
                          className={
                            selectedMenu === ele?.header
                              ? 'inspection-menu-items-Active'
                              : 'inspection-menu-items'
                          }
                        >
                          {ele?.header}
                          <br />
                          <span>{ele?.Value}</span>
                        </div>
                      );
                    })}
                  </div>
                  <div className="inspection-report-details-btn-cnt">
                    <p>Repair Estimate</p>
                    <Button
                      className="btn-view-photos"
                      onClick={() => alert('Repair details')}
                    >
                      View Estimate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {vechilReportData?.map((ele, index) => {
              return (
                <div
                  id={ele?.header}
                  key={index}
                  className="inspection-report-menu-container "
                >
                  <p className="menu-item-header">
                    <strong>{ele?.header} </strong> <span>{ele.Value} </span>{' '}
                    <span style={{ fontFamily: 'matter', color: '#9092a3' }}>
                      points of 100
                    </span>
                  </p>
                  <BorderLinearProgress
                    variant="determinate"
                    sx={{ marginTop: '40px', height: '12px' }}
                    value={ele?.Value}
                  />
                  {ele?.Description?.map((desc, descIndex) => {
                    return (
                      <div className="menu-item-description-contents">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <p
                            style={{
                              flex: '1 1 0%',
                              fontSize: '16px',
                              lineHeight: '24px',
                              color: 'black',
                            }}
                          >
                            {desc?.Label}
                          </p>
                          <p
                            style={{
                              flex: '1 1 0%',
                              fontSize: '24px',
                              lineHeight: '32px',
                              color:
                                desc?.Rating === 'Poor' ? 'red' : '#70BF44',
                            }}
                          >
                            {desc?.Rating}
                          </p>
                        </div>
                        {desc?.Comments && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <div
                              style={{
                                width: '50%',
                                display: 'flex',
                                flexFlow: 'wrap',
                                flexDirection: 'column',
                              }}
                            >
                              {/* <p
                                style={{
                                  color: desc?.Rating === "Poor" && "red",
                                }}
                              >
                                Comments:
                              </p> */}
                              <p
                                style={{
                                  color: desc?.Rating === 'Poor' && 'red',
                                  maxWidth: '530px',
                                  fontSize: '16px',
                                  lineHeight: '28px',
                                }}
                              >
                                {desc?.Comments}
                              </p>
                            </div>
                          </div>
                        )}
                        {desc?.Image && (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <div
                              style={{
                                width: '50%',
                                display: 'flex',
                                flexFlow: 'wrap',
                              }}
                            >
                              <img
                                src={Si1}
                                style={{
                                  width: '110px',
                                  height: '82px',
                                  marginRight: '10px',
                                  marginTop: '10px',
                                  cursor: 'pointer',
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            <Divider />
            <div className="vechile-rating-guide">
              <h1>Rating Guide</h1>
              <div className="vechile-rating-guide-container">
                {vechileRatingGuideData?.map((ele, index) => {
                  return (
                    <div key={index} className="vechile-rating-guide-content">
                      <div className="vechile-rating-label">{ele.label}</div>
                      <div className="vechile-rating-value">{ele.value}</div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider />
            <div className="sample-report-tandc">
              <p>
                Terms and conditions{' '}
                {isExpand ? (
                  <span onClick={() => setIsExpand(!isExpand)}>
                    Collapse
                    <KeyboardArrowUpIcon style={{ position: 'absolute' }} />
                  </span>
                ) : (
                  <span onClick={() => setIsExpand(!isExpand)}>
                    Expand
                    <KeyboardArrowDownIcon style={{ position: 'absolute' }} />
                  </span>
                )}
              </p>
              {isExpand && (
                <div className="sample-report-tandc-desc">
                  <h1>
                    Wisedrive technologies private limited terms & conditions,
                    refund policy, disclaimer
                  </h1>
                  <p className="tandc-header">Overview</p>
                  <p className="tandc-desc">
                    This website is operated by WiseDrive. Throughout the site,
                    the terms “we”, “us” and “our” refer to Wisedrive
                    Technologies Private Limited. Wisedrive Technologies Private
                    Limited offers this website, including all information,
                    tools and services available from this site to you, the
                    user, conditioned upon your acceptance of all terms,
                    conditions, policies and notices stated here. By visiting
                    our site and/ or purchasing something from us, you engage in
                    our “Service” and agree to be bound by the following terms
                    and conditions (“Terms of Service”, “Terms”), including
                    those additional terms and conditions and policies
                    referenced herein and/or available by hyperlink. These Terms
                    of Service apply to all users of the site, including without
                    limitation users who are browsers, vendors, customers,
                    merchants, and/ or contributors of content. Please read
                    these Terms of Service carefully before accessing or using
                    our website. By accessing or using any part of the site, you
                    agree to be bound by these Terms of Service. If you do not
                    agree to all the terms and conditions of this agreement,
                    then you may not access the website or use any services. If
                    these Terms of Service are considered an offer, acceptance
                    is expressly limited to these Terms of Service. Any new
                    features or tools which are added to the current store shall
                    also be subject to the Terms of Service. You can review the
                    most current version of the Terms of Service at any time on
                    this page. We reserve the right to update, change or replace
                    any part of these Terms of Service by posting updates and/or
                    changes to our website. It is your responsibility to check
                    this page periodically for changes. Your continued use of or
                    access to the website following the posting of any changes
                    constitutes acceptance of those changes.
                  </p>
                  <p className="tandc-header">General condition</p>
                  <p className="tandc-desc">
                    We reserve the right to refuse service to anyone for any
                    reason at any time. You understand that your content (not
                    including credit card information), may be transferred
                    unencrypted and involve (a) transmissions over various
                    networks; and (b) changes to conform and adapt to technical
                    requirements of connecting networks or devices. Credit card
                    information is always encrypted during transfer over
                    networks. You agree not to reproduce, duplicate, copy, sell,
                    resell or exploit any portion of the Service, use of the
                    Service, or access to the Service or any contact on the
                    website through which the service is provided, without
                    express written permission by us. The headings used in this
                    agreement are included for convenience only and will not
                    limit or otherwise affect these Terms.
                  </p>
                  <p className="tandc-header">
                    Accuracy, comletness & timeliness of information
                  </p>
                  <p className="tandc-desc">
                    We are not responsible if information made available on this
                    site is not accurate, complete or current. The material on
                    this site is provided for general information only and
                    should not be relied upon or used as the sole basis for
                    making decisions without consulting primary, more accurate,
                    more complete or more timely sources of information. Any
                    reliance on the material on this site is at your own risk.
                    This site may contain certain historical information.
                    Historical information, necessarily, is not current and is
                    provided for your reference only. We reserve the right to
                    modify the contents of this site at any time, but we have no
                    obligation to update any information on our site. You agree
                    that it is your responsibility to monitor changes to our
                    site.
                  </p>
                  <p className="tandc-header">
                    Modifications to the service and prices
                  </p>
                  <p className="tandc-desc">
                    Prices for our products are subject to change without
                    notice. We reserve the right at any time to modify or
                    discontinue the Service (or any part or content thereof)
                    without notice at any time. We shall not be liable to you or
                    to any third-party for any modification, price change,
                    suspension or discontinuance of the Service.
                  </p>
                  <p className="tandc-header">Products or Services</p>
                  <p className="tandc-desc">
                    Certain products or services may be available exclusively
                    online through the website. These products or services may
                    have limited quantities and are subject to return or
                    exchange only according to our Return Policy. We have made
                    every effort to display as accurately as possible the colors
                    and images of our products that appear at the store. We
                    cannot guarantee that your computer monitor's display of any
                    color will be accurate. We reserve the right, but are not
                    obligated, to limit the sales of our products or Services to
                    any person, geographic region or jurisdiction. We may
                    exercise this right on a case-by-case basis. We reserve the
                    right to limit the quantities of any products or services
                    that we offer. All descriptions of products or product
                    pricing are subject to change at anytime without notice, at
                    the sole discretion of us. We reserve the right to
                    discontinue any product at any time. Any offer for any
                    product or service made on this site is void where
                    prohibited. We do not warrant that the quality of any
                    products, services, information, or other material purchased
                    or obtained by you will meet your expectations, or that any
                    errors in the Service will be corrected.
                  </p>
                  <p className="tandc-header">
                    Accuracy of billing and account information
                  </p>
                  <p className="tandc-desc">
                    We reserve the right to refuse any order you place with us.
                    We may, in our sole discretion, limit or cancel quantities
                    purchased per person, per household or per order. These
                    restrictions may include orders placed by or under the same
                    customer account, the same credit card, and/or orders that
                    use the same billing and/or shipping address. In the event
                    that we make a change to or cancel an order, we may attempt
                    to notify you by contacting the e-mail and/or billing
                    address/phone number provided at the time the order was
                    made. We reserve the right to limit or prohibit orders that,
                    in our sole judgment, appear to be placed by dealers,
                    resellers or distributors. You agree to provide current,
                    complete and accurate purchase and account information for
                    all purchases made at our store. You agree to promptly
                    update your account and other information, including your
                    email address and credit card numbers and expiration dates,
                    so that we can complete your transactions and contact you as
                    needed. For more detail, please review our Returns Policy.
                  </p>
                  <p className="tandc-header">Optional tools</p>
                  <p className="tandc-desc">
                    We may provide you with access to third-party tools over
                    which we neither monitor nor have any control nor input. You
                    acknowledge and agree that we provide access to such tools
                    ”as is” and “as available” without any warranties,
                    representations or conditions of any kind and without any
                    endorsement. We shall have no liability whatsoever arising
                    from or relating to your use of optional third-party tools.
                    Any use by you of optional tools offered through the site is
                    entirely at your own risk and discretion and you should
                    ensure that you are familiar with and approve of the terms
                    on which tools are provided by the relevant third-party
                    provider(s). We may also, in the future, offer new services
                    and/or features through the website (including, the release
                    of new tools and resources). Such new features and/or
                    services shall also be subject to these Terms of Services.
                  </p>
                  <p className="tandc-header">Third-party links</p>
                  <p className="tandc-desc">
                    Certain content, products and services available via our
                    Service may include materials from third-parties.
                    Third-party links on this site may direct you to third-party
                    websites that are not affiliated with us. We are not
                    responsible for examining or evaluating the content or
                    accuracy and we do not warrant and will not have any
                    liability or responsibility for any third-party materials or
                    websites, or for any other materials, products, or services
                    of third-parties. We are not liable for any harm or damages
                    related to the purchase or use of goods, services,
                    resources, content, or any other transactions made in
                    connection with any third-party websites. Please review
                    carefully the third-party's policies and practices and make
                    sure you understand them before you engage in any
                    transaction. Complaints, claims, concerns, or questions
                    regarding third-party products should be directed to the
                    third-party.
                  </p>
                  <p className="tandc-header">
                    User comments, feedback and other submissions
                  </p>
                  <p className="tandc-desc">
                    If, at our request, you send certain specific submissions
                    (for example contest entries) or without a request from us
                    you send creative ideas, suggestions, proposals, plans, or
                    other materials, whether online, by email, by postal mail,
                    or otherwise (collectively, 'comments'), you agree that we
                    may, at any time, without restriction, edit, copy, publish,
                    distribute, translate and otherwise use in any medium any
                    comments that you forward to us. We are and shall be under
                    no obligation (1) to maintain any comments in confidence;
                    (2) to pay compensation for any comments; or (3) to respond
                    to any comments. We may, but have no obligation to, monitor,
                    edit or remove content that we determine in our sole
                    discretion are unlawful, offensive, threatening, libelous,
                    defamatory, pornographic, obscene or otherwise objectionable
                    or violates any party’s intellectual property or these Terms
                    of Service. You agree that your comments will not violate
                    any right of any third-party, including copyright,
                    trademark, privacy, personality or other personal or
                    proprietary right. You further agree that your comments will
                    not contain libelous or otherwise unlawful, abusive or
                    obscene material, or contain any computer virus or other
                    malware that could in any way affect the operation of the
                    Service or any related website. You may not use a false
                    e-mail address, pretend to be someone other than yourself,
                    or otherwise mislead us or third-parties as to the origin of
                    any comments. You are solely responsible for any comments
                    you make and their accuracy. We take no responsibility and
                    assume no liability for any comments posted by you or any
                    third-party.
                  </p>
                  <p className="tandc-header">Personal information</p>
                  <p className="tandc-desc">
                    Your submission of personal information through the store is
                    governed by our Privacy Policy.
                  </p>
                  <p className="tandc-header">
                    Erros, inaccuracies and omissions
                  </p>
                  <p className="tandc-desc">
                    Occasionally there may be information on our site or in the
                    Service that contains typographical errors, inaccuracies or
                    omissions that may relate to product descriptions, pricing,
                    promotions, offers, product shipping charges, transit times
                    and availability. We reserve the right to correct any
                    errors, inaccuracies or omissions, and to change or update
                    information or cancel orders if any information in the
                    Service or on any related website is inaccurate at any time
                    without prior notice (including after you have submitted
                    your order). We undertake no obligation to update, amend or
                    clarify information in the Service or on any related
                    website, including without limitation, pricing information,
                    except as required by law. No specified update or refresh
                    date applied in the Service or on any related website,
                    should be taken to indicate that all information in the
                    Service or on any related website has been modified or
                    updated.
                  </p>
                  <p className="tandc-header">Prohibited users</p>
                  <p className="tandc-desc">
                    In addition to other prohibitions as set forth in the Terms
                    of Service, you are prohibited from using the site or its
                    content: (a) for any unlawful purpose; (b) to solicit others
                    to perform or participate in any unlawful acts; (c) to
                    violate any international, federal, provincial or state
                    regulations, rules, laws, or local ordinances; (d) to
                    infringe upon or violate our intellectual property rights or
                    the intellectual property rights of others; (e) to harass,
                    abuse, insult, harm, defame, slander, disparage, intimidate,
                    or discriminate based on gender, sexual orientation,
                    religion, ethnicity, race, age, national origin, or
                    disability; (f) to submit false or misleading information;
                    (g) to upload or transmit viruses or any other type of
                    malicious code that will or may be used in any way that will
                    affect the functionality or operation of the Service or of
                    any related website, other websites, or the Internet; (h) to
                    collect or track the personal information of others; (i) to
                    spam, phish, pharm, pretext, spider, crawl, or scrape; (j)
                    for any obscene or immoral purpose; or (k) to interfere with
                    or circumvent the security features of the Service or any
                    related website, other websites, or the Internet. We reserve
                    the right to terminate your use of the Service or any
                    related website for violating any of the prohibited uses.
                  </p>
                  <p className="tandc-header">
                    Disclaimer of warranties; limitations of liability
                  </p>
                  <p className="tandc-desc">
                    We do not guarantee, represent or warrant that your use of
                    our service will be uninterrupted, timely, secure or
                    error-free. We do not warrant that the results that may be
                    obtained from the use of the service will be accurate or
                    reliable. You agree that from time to time we may remove the
                    service for indefinite periods of time or cancel the service
                    at any time, without notice to you. You expressly agree that
                    your use of, or inability to use, the service is at your
                    sole risk. The service and all products and services
                    delivered to you through the service are (except as
                    expressly stated by us) provided 'as is' and 'as available'
                    for your use, without any representation, warranties or
                    conditions of any kind, either express or implied, including
                    all implied warranties or conditions of merchantability,
                    merchantable quality, fitness for a particular purpose,
                    durability, title, and non-infringement. In no case shall
                    Wisedrive Technologies Private Limited., our directors,
                    officers, employees, affiliates, agents, contractors,
                    interns, suppliers, service providers or licensors be liable
                    for any injury, loss, claim, or any direct, indirect,
                    incidental, punitive, special, or consequential damages of
                    any kind, including, without limitation lost profits, lost
                    revenue, lost savings, loss of data, replacement costs, or
                    any similar damages, whether based in contract, tort
                    (including negligence), strict liability or otherwise,
                    arising from your use of any of the service or any products
                    procured using the service, or for any other claim related
                    in any way to your use of the service or any product,
                    including, but not limited to, any errors or omissions in
                    any content, or any loss or damage of any kind incurred as a
                    result of the use of the service or any content (or product)
                    posted, transmitted, or otherwise made available via the
                    service, even if advised of their possibility. Because some
                    states or jurisdictions do not allow the exclusion or the
                    limitation of liability for consequential or incidental
                    damages, in such states or jurisdictions, our liability
                    shall be limited to the maximum extent permitted by law.
                  </p>
                  <p className="tandc-header">Indemnification</p>
                  <p className="tandc-desc">
                    You agree to indemnify, defend and hold harmless Wisedrive
                    Technologies Private Limited. and our parent, subsidiaries,
                    affiliates, partners, officers, directors, agents,
                    contractors, licensors, service providers, subcontractors,
                    suppliers, interns and employees, harmless from any claim or
                    demand, including reasonable attorneys’ fees, made by any
                    third-party due to or arising out of your breach of these
                    Terms of Service or the documents they incorporate by
                    reference, or your violation of any law or the rights of a
                    third-party.
                  </p>
                  <p className="tandc-header">Severability</p>
                  <p className="tandc-desc">
                    In the event that any provision of these Terms of Service is
                    determined to be unlawful, void or unenforceable, such
                    provision shall nonetheless be enforceable to the fullest
                    extent permitted by applicable law, and the unenforceable
                    portion shall be deemed to be severed from these Terms of
                    Service, such determination shall not affect the validity
                    and enforceability of any other remaining provisions.
                  </p>
                  <p className="tandc-header">Termination</p>
                  <p className="tandc-desc">
                    The obligations and liabilities of the parties incurred
                    prior to the termination date shall survive the termination
                    of this agreement for all purposes. These Terms of Service
                    are effective unless and until terminated by either you or
                    us. You may terminate these Terms of Service at any time by
                    notifying us that you no longer wish to use our Services, or
                    when you cease using our site. If in our sole judgment you
                    fail, or we suspect that you have failed, to comply with any
                    term or provision of these Terms of Service, we also may
                    terminate this agreement at any time without notice and you
                    will remain liable for all amounts due up to and including
                    the date of termination; and/or accordingly may deny you
                    access to our Services (or any part thereof).
                  </p>
                  <p className="tandc-header">Entire Agreement</p>
                  <p className="tandc-desc">
                    The failure of us to exercise or enforce any right or
                    provision of these Terms of Service shall not constitute a
                    waiver of such right or provision. These Terms of Service
                    and any policies or operating rules posted by us on this
                    site or in respect to The Service constitutes the entire
                    agreement and understanding between you and us and govern
                    your use of the Service, superseding any prior or
                    contemporaneous agreements, communications and proposals,
                    whether oral or written, between you and us (including, but
                    not limited to, any prior versions of the Terms of Service).
                    Any ambiguities in the interpretation of these Terms of
                    Service shall not be construed against the drafting party.
                  </p>
                  <p className="tandc-header">Governing law</p>
                  <p className="tandc-desc">
                    These Terms of Service and any separate agreements whereby
                    we provide you Services shall be governed by and construed
                    in accordance with the laws of India and jurisdiction of
                    Bengaluru, Karnataka.
                  </p>
                  <p className="tandc-header">Changes to terms of service</p>
                  <p className="tandc-desc">
                    You can review the most current version of the Terms of
                    Service at any time at this page. We reserve the right, at
                    our sole discretion, to update, change or replace any part
                    of these Terms of Service by posting updates and changes to
                    our website. It is your responsibility to check our website
                    periodically for changes. Your continued use of or access to
                    our website or the Service following the posting of any
                    changes to these Terms of Service constitutes acceptance of
                    those changes.
                  </p>
                  <p className="tandc-header">Contact information</p>
                  <p className="tandc-desc">
                    Questions about the Terms of Service should be sent to us at
                    customercare@wisedrive.com.
                  </p>
                  <p className="tandc-header">Wisedrive Refund Policy.</p>
                  <p className="tandc-desc">
                    wisedrive takes utmost care to cater to you with the best of
                    services and smooth processes. However, you can find the
                    following to your aid in case you face an issue with our
                    services No refunds offered if the attendee does not attend
                    the particular session. The amount paid gets credited to
                    your wisedrive account. You can choose to use this amount
                    while buying any other course on our website. If any
                    particular session is cancelled, we offer a later date to
                    hold the session. If a session is cancelled entirely, we add
                    50 units to your wallet (valid for 6 months) along with the
                    amount you've invested which you can debit on your next
                    purchase. Under Purchase Recorded Version and Best Value
                    Package sections, we review refunding possibilities based on
                    customer requests mailed to customercare@wisedrive.com.
                    <br />
                    Force majure Clause Notwithstanding anything to the contrary
                    contained herein, wisedrive shall not be liable for any
                    delays or failures in performance resulting from acts beyond
                    its reasonable control including, without limitation, acts
                    of God, acts of war or terrorism, pandemic or epidemic,
                    shortage of supply, breakdowns or malfunctions,
                    interruptions or malfunction of computer facilities, or loss
                    of data due to power failures or mechanical difficulties
                    with information storage or retrieval systems, labor
                    difficulties or civil unrest (the ‘Act of God events’).
                    Notwithstanding the foregoing, in the event of such an
                    occurrence, each party agrees to make a good faith effort to
                    perform its obligations hereunder. Considering the act of
                    God events, you have the option either to reschedule the
                    session or you will be entitled to obtain refund your wallet
                    points.
                    <br />
                    <ul>
                      <li>
                        1. Service cancellation refund will be processed to the
                        Customer wisedrive Wallet with in 48hrs of cancellation
                        requested received.
                      </li>
                      <li>
                        2. wisedrive Wallet amount can be used for other
                        services or products which are available in wisedrive
                        Platform/Application.
                      </li>
                      <li>
                        3. If the Wallet amount is unused for more than 6
                        months, Amount will be Refunded via Net banking or other
                        modes of payment like Gift Voucher Etc.
                      </li>
                    </ul>
                  </p>
                  <p className="tandc-header">Wisedrive Disclaimer.</p>
                  <p className="tandc-desc">
                    The information provided by wisedrive ("we," "us" or "our" )
                    on www.wisedrive.com (the "Site") and our mobile application
                    is for general informational purposes only. All information
                    on the site and our mobile application is provided in good
                    faith, however we make no representation or warranty of any
                    kind, express or implied, regarding the accuracy, adequacy,
                    validity, reliability, availability or completeness of any
                    information on the site or our mobile application. UNDER NO
                    CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS
                    OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
                    SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY
                    INFORMATION PROVIDED ON THE SITE ANS OUR MOBILE APPLICATION.
                    YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR
                    RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE
                    APPLICATION IS SOLELY AT YOUR OWN RISK.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SampleReport;
