import React from 'react';
import './queryComponent.css';

import img1 from '../../assets/images/queryComponent/oneImg.png';
import img2 from '../../assets/images/queryComponent/twoImg.png';
import img3 from '../../assets/images/queryComponent/threeImg.png';
import img4 from '../../assets/images/queryComponent/fourimg.png';
import { Divider } from '@mui/material';

function QueryComponent() {
  return (
    <section className="querySection">
      <div id="how-it-works" className="container">
        <div className="commonHeading">
          <p className="query-sub-header">How it works ?</p>
          <h2>Buying pre-owned car has never been this simple!</h2>
          <p style={{ textAlign: 'justify' }}>
            Send “HI” on whatsapp to 73380 73380 to buy inspection plan, book
            inspection, schedule inspection, reschedule inspection or to talk to
            experts about your car. We are just a “Hi” away to assist you.
          </p>
        </div>
        <div className="query-cards-container">
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img1}></img>
              <h5>Buy Inspection Plan</h5>
            </div>
            <Divider
              style={{
                marginBottom: '1rem',
                marginTop: '1.5rem',
                borderColor: '#0c286a',
              }}
            />
            <p>Choose the best inspection package for your car.</p>
          </div>
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img2}></img>
              <h5>Schedule Inspection</h5>
            </div>
            <Divider
              style={{
                marginBottom: '1rem',
                marginTop: '1.5rem',
                borderColor: '#0c286a',
              }}
            />
            <p>
              Send us "Hi" on{' '}
              <span style={{ fontFamily: 'matterBold' }}>
                Whatsapp 73380 73380{' '}
              </span>{' '}
              or <br />
              call us at{' '}
              <span style={{ fontFamily: 'matterBold' }}>7353 34 35 36 </span>
            </p>
          </div>
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img3}></img>
              <h5> Get expert Recommendations</h5>
            </div>
            <Divider
              style={{
                marginBottom: '1rem',
                marginTop: '1.5rem',
                borderColor: '#0c286a',
              }}
            />
            <p>
              Talk to our in-house experts on repair estimates, best price to
              buy and other options before finalising the car
            </p>
          </div>
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img4}></img>
              <h5> Buy your dream car</h5>
            </div>
            <Divider
              style={{
                marginTop: '1.5rem',
                marginBottom: '1rem',
                borderColor: '#0c286a',
              }}
            />
            <p>
              Activate Warranty for your dream car and guarantee peace of mind
              post purchase.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QueryComponent;
