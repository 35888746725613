import moment from "moment";
import CryptoJS from "crypto-js";

export const checkIsValidFormat = (value = "", format) => {
  return format.test(value);
};

export const dateFormatChanger = (date, formate = "YYYY-MM-DD") => {
  const newDate = moment(date).format(formate);
  return newDate;
};

export const makeLabel = (str) => {
  let temp = str.charAt(0).toUpperCase();
  for (let i = 1; i < str.length; i++) {
    if (str[i].toUpperCase() === str[i]) {
      temp += " " + str[i];
    } else {
      temp += str[i];
    }
  }
  return temp;
};

export const AcceptOnlyAlphabate = (value) => {
  const alphabet = /^[a-zA-Z\s]+$/;
  return alphabet.test(value) || value === "";
};

export const AcceptOnlyNumber = (value) => {
  // const number = /^[0-9]{0,10}$/;  // Accept number from 0 to 9
  const number = /^[6789][0-9]{0,9}$/; //  Accept number start from 6 to 9
  return number.test(value) || value === "";
};
export const formatPrice = (price) => {
  // console.log(0);
  if (price === 0) {
    return price;
  }
  if (price) {
    const formatted = new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 15,
    }).format(price);
    return formatted;
  } else {
    return "";
  }
  // Expected output: "1,23,000"
};

function DecryptData(encryptedData, secretKey) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

export function EncryptData(data, secretKey) {
  const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
  return encryptedData;
}

export function NumberToWords({ number }) {
  console.log("Number", number);
  const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const thousands = ["", "thousand", "million", "billion", "trillion"];

  const convertThreeDigits = (num) => {
    let result = "";

    const hundreds = Math.floor(num / 100);
    const tensUnits = num % 100;

    if (hundreds > 0) {
      result += ones[hundreds] + " hundred ";
    }

    if (tensUnits < 10) {
      result += ones[tensUnits];
    } else if (tensUnits < 20) {
      result += teens[tensUnits - 10];
    } else {
      const tensDigit = Math.floor(tensUnits / 10);
      const onesDigit = tensUnits % 10;
      result += tens[tensDigit] + " " + ones[onesDigit];
    }

    return result.trim();
  };

  const convertToWords = (num) => {
    if (num === 0) return "zero";

    let result = "";
    let chunkIndex = 0;

    while (num > 0) {
      const chunk = num % 1000;
      if (chunk !== 0) {
        result =
          convertThreeDigits(chunk) +
          " " +
          thousands[chunkIndex] +
          " " +
          result;
      }
      num = Math.floor(num / 1000);
      chunkIndex++;
    }

    return result.trim();
  };

  return <div>{convertToWords(number)}</div>;
}

export function convert(n) {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
