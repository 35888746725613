import React, { useState } from 'react';
import './BuyNow.css';
import DoneIcon from '@mui/icons-material/Done';
import { useTheme } from '@mui/material/styles';
import { Input } from '@mui/base/Input';

import {
  Button,
  Paper,
  TextField,
  Link,
  Chip,
  InputAdornment,
} from '@mui/material';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddIcon from '@mui/icons-material/Add';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
  Close,
  Height,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@mui/icons-material';
import {
  AcceptOnlyAlphabate,
  AcceptOnlyNumber,
} from '../../utils/commonFunctions';
// import OTPInput, { ResendOTP } from "otp-input-react";
import OtpInput from 'react-otp-input';
import Autocomplete from '@mui/material/Autocomplete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PBuyBackGrey from '../../assets/images/buyNowPopup/premium-buyback-grey.png';
import PWarrantyGrey from '../../assets/images/buyNowPopup/premium-warranty-grey.png';
import PBuyBack from '../../assets/images/buyNowPopup/premium-buyback.png';
import PWarranty from '../../assets/images/buyNowPopup/premium-warranty.png';
import PDiscount from '../../assets/images/buyNowPopup/PDiscount.png';
import PDiscountGrey from '../../assets/images/buyNowPopup/PDiscount-grey.png';
import PPriceAdvice from '../../assets/images/buyNowPopup/PPrice-advice.png';
import PPriceAdviceGrey from '../../assets/images/buyNowPopup/PPrice-advice-grey.png';
import PClaimAssist from '../../assets/images/buyNowPopup/PClaim-Assist.png';
import PClaimAssistGrey from '../../assets/images/buyNowPopup/PClaim-Assist-grey.png';
import PRepairEstimate from '../../assets/images/buyNowPopup/Prepair-estimate.png';
import PRepairEstimateGrey from '../../assets/images/buyNowPopup/Prepair-estimate-grey.png';
import { styled } from '@mui/material/styles';
const StyledTextField = styled(TextField)`
  & label {
    color: white;
  }
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: white; // - Set the Input border
    }
    &:hover fieldset {
      border-color: white; // - Set the Input border when parent has :hover
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: white;
    }
  }
`;
const steps = [
  {
    label: 'Data collection',
    description: `Customer Name, Mobile Number and City of inspection … with a button to Verify OTP`,
    btnLabel: 'Get OTP',
  },
  {
    label: 'OTP',
    description: ' ⁠4 boxes for OTP and a butting for confirm OTP',
    btnLabel: 'Confirm OTP',
  },
  {
    label: 'Payment',
    description: `payment process (PG integration)`,
    btnLabel: 'Open Whatsapp',
  },
  // {
  //   label: "Confirmation",
  //   description: `payment process (PG integration)`,
  //   btnLabel: "Confirm",
  // },
];
const top100Films = [
  { title: 'Pulp Fiction', year: 1994 },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
];
const BuyNowComponent = ({ plantoBuy, handleUpgradePlan }) => {
  const theme = useTheme();
  const [isStepperVisible, setIsStepperVisible] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;
  const [btnIsActive, setBtnIsActive] = useState(2);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [otp, setOtp] = useState('');

  const [validateName, setValidateName] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [numberError, setNumberError] = useState(false);

  const handleProceed = () => {
    setActiveStep(0);
    setIsStepperVisible(true);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep == 0) {
      setIsStepperVisible(false);
      setActiveStep(0);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // step 1
  const handleName = (e) => {
    setValidateName(false);
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setName(value);
    }
  };

  const handleNumber = (e) => {
    setValidateNumber(false);
    setNumberError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setPhone(value);
    }
  };
  const handleCity = (e) => {
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setCity(value);
    }
  };
  const getOtp = async (event) => {
    event.preventDefault();
  };
  return (
    <div>
      <div className="buynow-container">
        <div className="buyNow-header">
          <h4>
            You have selected{' '}
            <span style={{ color: '#eb6d47' }}>{plantoBuy?.planTitle}</span>{' '}
            Plan
          </h4>
          <p>Select the number of cars you would like to get inspected. </p>
        </div>
        <div className="buyNow-content">
          <div style={{ width: '60%' }}>
            <div>
              <h6 style={{ fontFamily: 'matter', marginBottom: '10px' }}>
                <RocketLaunchIcon /> Inspection  Details{' '}
                <span>
                  <Button
                    variant="contained"
                    style={{ fontSize: '20px', textDecoration: 'none' }}
                    onClick={handleUpgradePlan}
                    disabled={plantoBuy?.planTitle !== 'Basic'}
                    sx={{
                      backgroundColor: '#003cc5',
                      textTransform: 'none',
                      fontFamily: 'matter',
                      fontSize: '14px !important',
                      padding: '4px 10px !important',
                    }}
                    endIcon={<ArrowForwardIcon fontSize="small" />}
                  >
                    Upgrade to Premimum{' '}
                  </Button>
                </span>
              </h6>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '40px',
              }}
            >
              <div>
                {plantoBuy?.popUpdata?.map((ele, index) => {
                  return (
                    <>
                      {ele?.planBenifitsBasic?.map((basic, baseIndex) => {
                        return (
                          <p key={baseIndex}>
                            <span
                              style={{
                                display: 'flex',
                                fontFamily: 'matterMedium',
                                alignItems: 'center',
                                fontSize: '16px',
                              }}
                            >
                              <DoneIcon className="doneIconModal" />
                              {basic?.desc}
                            </span>
                          </p>
                        );
                      })}
                      {ele?.planBenifitsPremium?.map((pre, preIndex) => {
                        return (
                          <p key={preIndex}>
                            <span
                              style={{
                                color:
                                  plantoBuy?.planTitle == 'Basic' && '#B2B7BE',
                                fontFamily:
                                  plantoBuy?.planTitle === 'Basic'
                                    ? 'matterMedium'
                                    : 'matterMedium',
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                              }}
                            >
                              <DoneIcon
                                className="doneIconModal"
                                style={{
                                  color:
                                    plantoBuy?.planTitle === 'Basic' &&
                                    '#B2B7BE',
                                }}
                              />
                              {pre?.desc}
                            </span>
                          </p>
                        );
                      })}
                    </>
                  );
                })}
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '12px',
                    margin: '12px',
                  }}
                >
                  <img
                    src={
                      plantoBuy?.planTitle === 'Basic'
                        ? PPriceAdviceGrey
                        : btnIsActive == 2
                        ? PPriceAdvice
                        : PPriceAdviceGrey
                    }
                    style={{ marginTop: '15px' }}
                    width="100px"
                    height="100px"
                    alt="SVG"
                  />
                  <img
                    src={
                      plantoBuy?.planTitle === 'Basic'
                        ? PRepairEstimateGrey
                        : btnIsActive == 2
                        ? PRepairEstimate
                        : PRepairEstimateGrey
                    }
                    style={{ marginTop: '15px' }}
                    width="100px"
                    height="100px"
                    alt="SVG"
                  />
                  <img
                    src={
                      plantoBuy?.planTitle === 'Basic'
                        ? PDiscountGrey
                        : btnIsActive == 2
                        ? PDiscount
                        : PDiscountGrey
                    }
                    style={{ marginTop: '15px' }}
                    width="100px"
                    height="100px"
                    alt="SVG"
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '12px',
                    margin: '12px',
                  }}
                >
                  <img
                    src={
                      plantoBuy?.planTitle === 'Basic'
                        ? PWarrantyGrey
                        : btnIsActive == 2
                        ? PWarranty
                        : PWarrantyGrey
                    }
                    style={{ marginTop: '15px' }}
                    width="100px"
                    height="100px"
                    alt="SVG"
                  />
                  <img
                    src={
                      plantoBuy?.planTitle === 'Basic'
                        ? PBuyBackGrey
                        : btnIsActive == 2
                        ? PBuyBack
                        : PBuyBackGrey
                    }
                    style={{ marginTop: '15px' }}
                    width="100px"
                    height="100px"
                    alt="SVG"
                  />
                  <img
                    src={
                      plantoBuy?.planTitle === 'Basic'
                        ? PClaimAssistGrey
                        : btnIsActive == 2
                        ? PClaimAssist
                        : PClaimAssistGrey
                    }
                    style={{ marginTop: '15px' }}
                    width="100px"
                    height="100px"
                    alt="SVG"
                  />
                </div>
              </div>
            </div>
          </div>
          <Paper
            sx={{
              width: '40%',
              backgroundColor: '#003CC5',
              // paddingTop: "25px",
              boxShadow: '0px 10px 20px 0px rgba(164, 172, 179) !important',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {!isStepperVisible && (
              <>
                <div className="buyNow-invoice">
                  <Button
                    className={
                      btnIsActive == 1 ? 'btn-buyNow-active' : 'btn-buyNow'
                    }
                    onClick={() => setBtnIsActive(1)}
                    variant="outlined"
                  >
                    <div className="btn-buynow-inline">
                      <p style={{ fontSize: '16px', paddingTop: '2px' }}>
                        {' '}
                        1 Inspection
                      </p>
                      <p
                        style={{
                          paddingTop: '0px',
                          fontFamily: 'matterBold',
                        }}
                      >
                        Rs 999{' '}
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterMedium',
                          }}
                        >
                          {' '}
                          per Inspection
                        </span>
                      </p>
                    </div>
                  </Button>
                  <Button
                    className={
                      btnIsActive == 2 ? 'btn-buyNow-active' : 'btn-buyNow'
                    }
                    onClick={() => setBtnIsActive(2)}
                    variant="outlined"
                  >
                    <div className="btn-buynow-inline">
                      <p style={{ fontSize: '16px', paddingTop: '2px' }}>
                        {' '}
                        3 Inspections{' '}
                        {/* <Chip
                          sx={{
                            backgroundColor: "#eb6d47",
                            color: "white",
                            fontFamily: "matter",
                          }}
                          label="Best value"
                        /> */}
                      </p>
                      <p
                        style={{
                          paddingTop: '0px',
                          fontFamily: 'matterBold',
                        }}
                      >
                        Rs 899{' '}
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterMedium',
                          }}
                        >
                          {' '}
                          per Inspection
                        </span>
                      </p>
                    </div>
                  </Button>
                </div>
                <div className="buynow-tablesec">
                  <div className="bunow-table-content">
                    <div className="div-invoice">
                      <p>
                        Package Amount <br />
                        (₹ 999 x 3 inspections)
                      </p>
                      <p>₹ 200</p>
                    </div>
                    <div className="div-promocode">
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        placeholder="Add Promo Code"
                        sx={{
                          borderBottomColor: 'white',
                          '& .MuiInput-underline:after': {
                            borderBottomColor: 'white',
                          },
                          '& .MuiInput-underline': {
                            borderBottomColor: 'white',
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {' '}
                              <Link
                                onClick={() => alert('clicked')}
                                style={{
                                  marginBottom: '20px',
                                  color: '#eb6d47',
                                  textDecoration: 'none',
                                  fontFamily: 'matter',
                                }}
                              >
                                <Chip
                                  sx={{
                                    backgroundColor: '#E0FC00',
                                    color: '#0C286A',
                                    fontFamily: 'matter',
                                  }}
                                  label="Apply"
                                />
                              </Link>
                            </InputAdornment>
                          ),
                        }}
                        className="txt-promoCoe"
                      />
                    </div>
                    <div className="div-invoice" style={{ paddingTop: '10px' }}>
                      <p>Discount Applied </p>
                      <p>₹ 10</p>
                    </div>
                    <div className="div-invoice" style={{ paddingTop: '10px' }}>
                      <p>Tax (18% GST) </p>
                      <p>₹ 20</p>
                    </div>
                    <div className="div-invoice seperator desktop">
                      <>&nbsp;</>
                    </div>
                    <div className="div-invoice" style={{ paddingTop: '10px' }}>
                      <h4>Total Amount (INR)</h4>
                      <h3>₹ 100</h3>
                    </div>

                    <div className="div-invoice btn-modal-paynow">
                      <button onClick={handleProceed}>Pay Now</button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isStepperVisible && (
              <>
                {steps[activeStep].label === 'Data collection' && (
                  <div className="buyNow-paper">
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <Button
                        size="small"
                        onClick={handleBack}
                        sx={{
                          minWidth: '30px',
                          backgroungColor: 'white',
                          padding: '0px !important',
                          lineHeight: '0px !important',
                        }}
                        // disabled={activeStep === 0}
                      >
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <ArrowCircleLeftIcon
                            sx={{ color: 'white' }}
                            fontSize="large"
                          />
                        )}
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontFamily: 'matterBold',
                          fontSize: '30px',
                          lineHeight: '30px',
                        }}
                      >
                        Start Protecting
                      </p>
                      <p
                        style={{
                          fontFamily: 'matterBold',
                          fontSize: '30px',
                          lineHeight: '30px',
                        }}
                      >
                        your pre-owned Car
                      </p>
                    </div>
                    <div>
                      <div
                        style={{
                          marginTop: '20px',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        {/* <Typography>Name</Typography> */}
                        <StyledTextField
                          id="standard-basic"
                          variant="outlined"
                          value={name}
                          onChange={handleName}
                          label="Name"
                          sx={{
                            width: '75% !important',
                            borderRadius: '10px',
                            '&:hover': {
                              borderColor: 'white !important',
                            },
                          }}
                          inputProps={{
                            sx: {
                              color: 'white',
                              height: '10px !important',
                              fontFamily: 'matter !important',
                            },
                          }}
                        />
                        {validateName && (
                          <p style={{ color: 'red' }}>Please enter name</p>
                        )}
                      </div>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          flexDirection: 'column',
                        }}
                      >
                        {/* <Typography>Mobile Number</Typography> */}
                        <StyledTextField
                          id="standard-basic"
                          variant="outlined"
                          label="Mobile Number"
                          value={phone}
                          onChange={handleNumber}
                          sx={{
                            width: '75% !important',
                            borderRadius: '10px',
                          }}
                          inputProps={{
                            sx: {
                              color: 'white',
                              height: '10px !important',
                              fontFamily: 'matter !important',
                            },
                          }}
                        />
                        {validateNumber && (
                          <p style={{ color: 'red' }}>Please enter number</p>
                        )}
                        {numberError && (
                          <p style={{ color: 'red' }}>
                            Number is less than 10 digit
                          </p>
                        )}
                        <div>
                          <p
                            style={{
                              fontFamily: 'matter',
                              fontSize: '14px',
                              maxWidth: '350px',
                              textAlign: 'center',
                            }}
                          >
                            OTP will be sent to the above mobile number for
                            confirmation
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        marginBottom: '10px',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        // color="success"
                        onClick={handleNext}
                        sx={{
                          width: '75%',
                          backgroundColor: '#E0FC00',
                          color: '#0C286A',
                          '&:hover': {
                            backgroundColor: '#E0FC00 !important',
                            color: '#0C286A',
                          },
                        }}
                        disabled={
                          activeStep === maxSteps - 1 ||
                          name == '' ||
                          phone == ''
                        }
                      >
                        {steps[activeStep].btnLabel}
                        {theme.direction === 'rtl' ? (
                          <ArrowCircleLeftIcon
                            sx={{ color: 'white' }}
                            fontSize="large"
                          />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    </div>
                  </div>
                )}
                {steps[activeStep].label === 'OTP' && (
                  <div className="buyNow-otp">
                    <div>
                      <Button
                        size="small"
                        onClick={handleBack}
                        sx={{
                          padding: '0px !important',
                          lineHeight: '0px !important',
                        }}
                        // disabled={activeStep === 0}
                      >
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <ArrowCircleLeftIcon
                            sx={{ color: 'white' }}
                            fontSize="large"
                          />
                        )}
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontFamily: 'matterBold',
                          fontSize: '30px',
                          lineHeight: '30px',
                        }}
                      >
                        Please Verify
                      </p>
                      <p
                        style={{
                          fontFamily: 'matterBold',
                          fontSize: '30px',
                          lineHeight: '30px',
                        }}
                      >
                        Your Mobile Number
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <OtpInput
                        // sx={}
                        className="otp-input"
                        value={otp}
                        onChange={(e) => {
                          setOtp(e);
                        }}
                        numInputs={4}
                        // renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                      />
                      <p
                        style={{
                          maxWidth: '350px',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                          gap: '10px',
                          fontFamily: 'matter',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        An otp was sent to your {phone}. Please enter the 4
                        digit OTP to verify
                      </p>
                      <p>
                        <Link>Resend Otp</Link>
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '20px',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        sx={{
                          width: '75%',
                          backgroundColor: '#E0FC00',
                          color: '#0C286A',
                          '&:hover': {
                            backgroundColor: '#E0FC00 !important',
                            color: '#0C286A',
                          },
                        }}
                        onClick={handleNext}
                        disabled={otp?.length !== 4}
                      >
                        {steps[activeStep].btnLabel}
                        {theme.direction === 'rtl' ? (
                          <ArrowCircleLeftIcon
                            sx={{ color: 'white' }}
                            fontSize="large"
                          />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    </div>
                  </div>
                )}
                {steps[activeStep].label === 'Payment' && (
                  <div className="buyNow-otp">
                    <div>
                      <Button
                        size="small"
                        onClick={handleBack}
                        sx={{
                          padding: '0px !important',
                          lineHeight: '0px !important',
                        }}
                        // disabled={activeStep === 0}
                      >
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <ArrowCircleLeftIcon
                            sx={{ color: 'white' }}
                            fontSize="large"
                          />
                        )}
                      </Button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <CheckCircleIcon
                        fontSize="large"
                        style={{ color: '#E0FC00' }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontFamily: 'matterBold',
                          fontSize: '30px',
                          lineHeight: '30px',
                        }}
                      >
                        Payment Confirmed
                      </p>
                      <p
                        style={{
                          fontFamily: 'matter',
                          fontSize: '18px',
                          textAlign: 'center',
                        }}
                      >
                        ConfirmationId:
                        <br />
                        SJHUSGJBD
                      </p>
                      <p
                        style={{
                          fontFamily: 'matter',
                          fontSize: '16px',
                          textAlign: 'center',
                          paddingTop: '40px',
                          paddingBottom: '40px',
                        }}
                      >
                        Thank you for subscribing to 3 Inspection package. Ypur
                        package is valid till 31st Aug 2024. To book inspection
                        plese send "Hi" to whatsapp number 73380 73380 or call
                        us at 73380 73380 from 8am to 6 pm Mon - Sat
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontFamily: 'matter',
                          fontSize: '18px',
                          textAlign: 'center',
                        }}
                      >
                        Payment Confirmation and instructions have been sent to{' '}
                        {phone} via sms and whatsapp
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        marginBottom: '20px',
                        justifyContent: 'space-around',
                      }}
                    >
                      <Button
                        size="large"
                        variant="contained"
                        sx={{
                          width: '75%',
                          backgroundColor: '#E0FC00',
                          color: '#0C286A',
                          '&:hover': {
                            backgroundColor: '#E0FC00 !important',
                            color: '#0C286A',
                          },
                        }}
                        // onClick={handleNext}
                        // disabled={otp?.length !== 4}
                      >
                        {steps[activeStep].btnLabel}
                        {/* {theme.direction === "rtl" ? (
                          <ArrowCircleLeftIcon
                            sx={{ color: "white" }}
                            fontSize="large"
                          />
                        ) : (
                          <KeyboardArrowRight />
                        )} */}
                      </Button>
                    </div>
                  </div>
                )}
                {/* {steps[activeStep].label === "Confirmation" && (
                  <div className="buyNow-otp">
                    <p>Payment Confirmed</p>
                  </div>
                )} */}
              </>
            )}
          </Paper>
        </div>
      </div>
      <div className="modal_note">
        <p>
          * Luxury car brands include Volvo, Mercedes Benz, BMW, Audi, Bugatti,
          Lamborgini, Ferrari etc...
        </p>
      </div>
    </div>
  );
};

export default BuyNowComponent;
