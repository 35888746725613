import EngineReport from '../assets/images/reports/engineReport.svg';
import Service from '../assets/images/reports/service.svg';
import Traffic from '../assets/images/reports/traffic.svg';
import Onboard from '../assets/images/reports/onboard.svg';
import Transmition from '../assets/images/reports/transmition.svg';

export const ValidationContainer = {
  carNumber: /^[A-Z0-9]*\d[A-Z0-9]$/,
  mobileNumberFormat: /^[6789]\d{9}$/,
  alphabet: /[A-Z]/,
};

export const healthReportData = [
  {
    icon: EngineReport,
    title: 'Engine Inspection report',
    description:
      'Detailed Overall inspection of the car internal parts and paintings.',
    reportLink: '/report-details',
    module_id: 1,
    unlocked: true,
  },
  {
    icon: Transmition,
    title: 'Transmission inspection report',
    description:
      'Complete detailed inspection report about car gearbox, clutch & gear oil.',
    reportLink: '/report-details',
    unlockLink: '/payment',
    unlockPrice: 'Unlock for ₹299',
    module_id: 2,
    unlocked: true,
  },
  {
    icon: Onboard,
    title: 'OBD Report',
    description:
      'Car detailed health report of sensors, and onboard electronics of car.',
    reportLink: '/report-details',
    unlockLink: '/payment',
    unlockPrice: 'Unlock for ₹499',
    module_id: 3,
    unlocked: false,
  },
  {
    icon: Traffic,
    title: 'Traffic Fines and Blacklist Details',
    description:
      'Get to know the car fines, blacklist status & many more legal info of car.',
    reportLink: '',
    unlockLink: '',
    module_id: 4,
    unlocked: false,
  },
  {
    icon: Service,
    title: 'Finance and insurance check',
    description: 'Complete service history of car in one glance.',
    reportLink: '',
    unlockLink: '',
    unlocked: false,
    module_id: 5,
  },
];
export const comingSoon = [
  {
    icon: Traffic,
    title: 'Traffic fines & blacklist details',
    description:
      'Get to know the car fines, blacklist status & many more legal info of car.',
    reportLink: '',
    unlockLink: '',
    unlockPrice: 'Coming Soon',
    unlocked: false,
  },
  {
    icon: Service,
    title: 'Service history',
    description: 'Complete service history of car in one glance.',
    reportLink: '',
    unlockLink: '',
    unlockPrice: 'Coming Soon',
    unlocked: false,
  },
];
export const checkoutEngine = [
  { title: 'Oil Pump', warranty: true, service: false, superCombo: true },
  {
    title: 'Crankshaft & Pulley',
    warranty: true,
    service: true,
    superCombo: true,
  },
  {
    title: 'Big end and main bearings',
    warranty: true,
    service: true,
    superCombo: true,
  },
  {
    title: 'Connecting rods',
    warranty: true,
    service: false,
    superCombo: '',
  },
  { title: 'Gudgeon Pins', warranty: true, service: '', superCombo: true },
  {
    title: 'Piston and Rings',
    warranty: true,
    service: '',
    superCombo: true,
  },
  {
    title: 'Inlet and exhaust valves',
    warranty: true,
    service: false,
    superCombo: true,
  },
];

export const planDetailsArray = [
  {
    inspectionPackageId: 1,
    tenancy: 6,
    packTitle: 'Basic',
    packCaption: null,
    smallPackage: {
      packageInstanceId: 2,
      packageId: 8,
      costPrice: 933,
      taxPercent: 18,
      validityDays: 365,
      totalPrice: 1100,
      inspectionCount: 1,
      inspectionFeatures: [
        {
          feature_name: 'Engine Health Check & Diagnostic',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Transmission Assessment',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Electrical System Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Mechanical Components Review',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Interior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Exterior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'OBD-2 Diagnostic Report',
          isvalid: 'NO',
        },
        {
          feature_name: 'Engine Control Module Analysis',
          isvalid: 'NO',
        },
        {
          feature_name: 'Body Control Module Inspection',
          isvalid: 'NO',
        },
        {
          feature_name: 'Accident History Inspection',
          isvalid: 'NO',
        },
        {
          feature_name: 'Finance Validity & Clearance Check',
          isvalid: 'NO',
        },
        {
          feature_name: 'Insurance Validity & Expiry Check',
          isvalid: 'NO',
        },
        {
          feature_name: 'Traffic Challans Verification',
          isvalid: 'NO',
        },
        {
          feature_name: 'Vehicle Crime Records Check',
          isvalid: 'NO',
        },
      ],
      addonFeatures: {
        addon_title: 'Additional benfits',
        features: [
          {
            'sub-title': 'Pre-Purchase Support',
            features: [
              {
                name: 'Buying price recommendation',
                isvalid: 'Yes',
              },
              {
                name: 'Repair Estimate',
                isvalid: 'Yes',
              },
              {
                name: 'Discount on insurance purchase',
                isvalid: 'Yes',
              },
            ],
          },
          {
            'sub-title': 'Post-Purchase Support',
            features: [
              {
                name: '60 Days Warranty',
                isvalid: 'No',
              },
              {
                name: '30 Days Buyback Gaurantee',
                isvalid: 'No',
              },
              {
                name: 'Insurance claim assistance',
                isvalid: 'No',
              },
            ],
          },
        ],
      },
      isActive: true,
      creationDate: '2024-07-27T07:25:54.000+00:00',
      lastModified: '2024-08-20T13:00:35.000+00:00',
    },
    highPackage: {
      packageInstanceId: 3,
      packageId: 9,
      costPrice: 2288,
      taxPercent: 18,
      validityDays: 365,
      totalPrice: 2700,
      inspectionCount: 3,
      inspectionFeatures: [
        {
          feature_name: 'Engine Health Check & Diagnostic',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Transmission Assessment',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Electrical System Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Mechanical Components Review',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Interior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Exterior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'OBD-2 Diagnostic Report',
          isvalid: 'NO',
        },
        {
          feature_name: 'Engine Control Module Analysis',
          isvalid: 'NO',
        },
        {
          feature_name: 'Body Control Module Inspection',
          isvalid: 'NO',
        },
        {
          feature_name: 'Accident History Inspection',
          isvalid: 'NO',
        },
        {
          feature_name: 'Finance Validity & Clearance Check',
          isvalid: 'NO',
        },
        {
          feature_name: 'Insurance Validity & Expiry Check',
          isvalid: 'NO',
        },
        {
          feature_name: 'Traffic Challans Verification',
          isvalid: 'NO',
        },
        {
          feature_name: 'Vehicle Crime Records Check',
          isvalid: 'NO',
        },
      ],
      addonFeatures: {
        addon_title: 'Additional benfits',
        features: [
          {
            'sub-title': 'Pre-Purchase Support',
            features: [
              {
                name: 'Buying price recommendation',
                isvalid: 'Yes',
              },
              {
                name: 'Repair Estimate',
                isvalid: 'Yes',
              },
              {
                name: 'Discount on insurance purchase',
                isvalid: 'Yes',
              },
            ],
          },
          {
            'sub-title': 'Post-Purchase Support',
            features: [
              {
                name: '60 Days Warranty',
                isvalid: 'No',
              },
              {
                name: '30 Days Buyback Gaurantee',
                isvalid: 'No',
              },
              {
                name: 'Insurance claim assistance',
                isvalid: 'No',
              },
            ],
          },
        ],
      },
      isActive: true,
      creationDate: '2024-07-27T07:26:15.000+00:00',
      lastModified: '2024-08-20T13:00:39.000+00:00',
    },
    isActive: true,
    creationDate: '2024-08-13T13:57:45.000+00:00',
    lastModified: '2024-08-13T16:56:59.000+00:00',
  },
  {
    inspectionPackageId: 4,
    tenancy: 6,
    packTitle: 'Premium',
    packCaption: null,
    smallPackage: {
      packageInstanceId: 10,
      packageId: 10,
      costPrice: 1102,
      taxPercent: 18,
      validityDays: 365,
      totalPrice: 1300,
      inspectionCount: 1,
      inspectionFeatures: [
        {
          feature_name: 'Engine Health Check & Diagnostic',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Transmission Assessment',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Electrical System Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Mechanical Components Review',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Interior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Exterior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'OBD-2 Diagnostic Report',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Engine Control Module Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Body Control Module Inspection',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Accident History Inspection',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Finance Validity & Clearance Check',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Insurance Validity & Expiry Check',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Traffic Challans Verification',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Vehicle Crime Records Check',
          isvalid: 'Yes',
        },
      ],
      addonFeatures: {
        addon_title: 'Additional benfits',
        features: [
          {
            'sub-title': 'Pre-Purchase Support',
            features: [
              {
                name: 'Buying price recommendation',
                isvalid: 'Yes',
              },
              {
                name: 'Repair Estimate',
                isvalid: 'Yes',
              },
              {
                name: 'Discount on insurance purchase',
                isvalid: 'Yes',
              },
            ],
          },
          {
            'sub-title': 'Post-Purchase Support',
            features: [
              {
                name: '60 Days Warranty',
                isvalid: 'No',
              },
              {
                name: '30 Days Buyback Gaurantee',
                isvalid: 'No',
              },
              {
                name: 'Insurance claim assistance',
                isvalid: 'No',
              },
            ],
          },
        ],
      },
      isActive: true,
      creationDate: '2024-08-02T02:39:53.000+00:00',
      lastModified: '2024-08-20T13:01:09.000+00:00',
    },
    highPackage: {
      packageInstanceId: 11,
      packageId: 11,
      costPrice: 2543,
      taxPercent: 18,
      validityDays: 365,
      totalPrice: 3000,
      inspectionCount: 3,
      inspectionFeatures: [
        {
          feature_name: 'Engine Health Check & Diagnostic',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Transmission Assessment',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Electrical System Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Mechanical Components Review',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Interior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Exterior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'OBD-2 Diagnostic Report',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Engine Control Module Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Body Control Module Inspection',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Accident History Inspection',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Finance Validity & Clearance Check',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Insurance Validity & Expiry Check',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Traffic Challans Verification',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Vehicle Crime Records Check',
          isvalid: 'Yes',
        },
      ],
      addonFeatures: {
        addon_title: 'Additional benfits',
        features: [
          {
            'sub-title': 'Pre-Purchase Support',
            features: [
              {
                name: 'Buying price recommendation',
                isvalid: 'Yes',
              },
              {
                name: 'Repair Estimate',
                isvalid: 'Yes',
              },
              {
                name: 'Discount on insurance purchase',
                isvalid: 'Yes',
              },
            ],
          },
          {
            'sub-title': 'Post-Purchase Support',
            features: [
              {
                name: '60 Days Warranty',
                isvalid: 'Yes',
              },
              {
                name: '30 Days Buyback Gaurantee',
                isvalid: 'Yes',
              },
              {
                name: 'Insurance claim assistance',
                isvalid: 'Yes',
              },
            ],
          },
        ],
      },
      isActive: true,
      creationDate: '2024-08-13T15:52:29.000+00:00',
      lastModified: '2024-08-20T13:01:45.000+00:00',
    },
    isActive: true,
    creationDate: '2024-08-13T16:22:17.000+00:00',
    lastModified: '2024-08-25T15:21:06.000+00:00',
  },
  {
    inspectionPackageId: 10,
    tenancy: 6,
    packTitle: 'Luxury',
    packCaption: null,
    smallPackage: null,
    highPackage: {
      packageInstanceId: 12,
      packageId: 12,
      costPrice: 4238,
      taxPercent: 18,
      validityDays: 365,
      totalPrice: 5000,
      inspectionCount: 1,
      inspectionFeatures: [
        {
          feature_name: 'Engine Health Check & Diagnostic',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Transmission Assessment',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Electrical System Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Mechanical Components Review',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Interior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Exterior Evaluation (With Photos)',
          isvalid: 'Yes',
        },
        {
          feature_name: 'OBD-2 Diagnostic Report',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Engine Control Module Analysis',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Body Control Module Inspection',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Accident History Inspection',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Finance Validity & Clearance Check',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Insurance Validity & Expiry Check',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Traffic Challans Verification',
          isvalid: 'Yes',
        },
        {
          feature_name: 'Vehicle Crime Records Check',
          isvalid: 'Yes',
        },
      ],
      addonFeatures: {
        addon_title: 'Additional benfits',
        features: [
          {
            'sub-title': 'Pre-Purchase Support',
            features: [
              {
                name: 'Buying price recommendation',
                isvalid: 'Yes',
              },
              {
                name: 'Repair Estimate',
                isvalid: 'Yes',
              },
              {
                name: 'Discount on insurance purchase',
                isvalid: 'Yes',
              },
            ],
          },
          {
            'sub-title': 'Post-Purchase Support',
            features: [
              {
                name: '60 Days Warranty',
                isvalid: 'Yes',
              },
              {
                name: '30 Days Buyback Gaurantee',
                isvalid: 'Yes',
              },
              {
                name: 'Insurance claim assistance',
                isvalid: 'Yes',
              },
            ],
          },
        ],
      },
      isActive: true,
      creationDate: '2024-08-19T02:37:06.000+00:00',
      lastModified: '2024-08-23T02:21:49.000+00:00',
    },
    isActive: true,
    creationDate: '2024-08-20T15:04:14.000+00:00',
    lastModified: '2024-09-05T07:58:51.000+00:00',
  },
];
