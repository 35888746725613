import React, { useState, useEffect, useContext } from 'react';
import './header.css';
import companyLogo from '../../assets/images/home/logo.svg';
import Bars from '../../assets/images/home/bars.svg';
import Close from '../../assets/images/home/close.svg';
// import Expand from "../../assets/images/header/expandMore.svg";
import Expand from '../../assets/images/header/menuIcon.png';
import { Link, useNavigate } from 'react-router-dom';
import WiseDriveLogoNew from '../../assets/images/header/wiseDrivelogoNew.png';
import FairCarLogo from '../../assets/images/header/FairCarLogo.png';
import { MyContext } from '../../context';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from 'axios';
import { SendIpOnHomeLoad } from '../../Api/PortalApis';

const Header = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const path = window.location.href.split('/')[3];
  const [active, setActive] = useState('');
  const [sticky, setSticky] = useState('');
  const [headerDropDownText, setHeaderDropDownText] = useState('');
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [openKycPopup, setOpenKycPopup] = useState(false);
  const { setOwnerPlanTabActive } = useContext(MyContext);
  const [isModalOpen, setIsmodalOpen] = useState(false);
  const [carNumber, setCarNumber] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  const toggleModal = () => {
    setIsmodalOpen((prevState) => !prevState);
    document.body.style.overflow = isModalOpen ? 'auto' : 'hidden';
  };

  async function sendIpOnLoad() {
    try {
      // get ip address from the ipfy api
      const res = await axios.get('https://api.ipify.org/?format=json');

      if (res.status === 200) {
        const userIp = res.data.ip;

        const data = {
          ip: userIp,
        };
        try {
          const resp = await SendIpOnHomeLoad(data);
          if (resp.status === 200) {
          }
        } catch (error) {
          console.error('Sending ip address failed', error);
        }
      } else {
        throw new Error('Failed to get ip address from the ipfy api');
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    sessionStorage.clear();

    // checkTimer();
    // sendIpOnLoad();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      // console.log(window.pageYOffset, "hello");
      // if (window.pageYOffset <= 500) {
      //   setSticky("sticky");
      // } else {
      //   setSticky("sticky");
      // }
      // let moving = window.pageYOffset;
      // if (window.pageYOffset > 100) {
      //   setVisible(position > moving);
      // }
      // setPosition(moving);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const cls = visible ? '' : 'hidden';

  const [isOpen, setIsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(!isOpen);

    // document.addEventListener("click", handleDocumentClick);
  };

  const closeNav = () => {
    setIsOpen(false);
    document.getElementById('myNav').style.height = '0%';
    // document.removeEventListener("click", handleDocumentClick);
  };

  const closeKycPopup = () => {
    setOpenKycPopup(false);
  };

  useEffect(() => {
    ShowHeaderDropdown();
  }, [path]);

  const ShowHeaderDropdown = () => {
    if (path === 'buyer') {
      setHeaderDropDownText('Buyer');
    } else if (path === 'owner') {
      setHeaderDropDownText('Owner');
    } else {
      setHeaderDropDownText('Owner');
    }
  };

  const ExplorePlansSwitchTabs = () => {
    if (path === 'buyer') {
      setOwnerPlanTabActive(1);
    } else if (path === 'owner') {
      setOwnerPlanTabActive(0);
    } else {
      setOwnerPlanTabActive(0);
    }

    navigate('/owner-pricing-plans');
    setActive('');
  };

  const handleCarNumber = (e) => {
    setCarNumber(e.target.value);
    if (e.target.value.length >= 6) {
      setDisableButton(false);
    } else if (e.target.value.length < 6) setDisableButton(true);
  };

  const fixBackground = () => {
    document.body.style.overflow = openKycPopup ? 'auto' : 'hidden';
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementsByTagName('header')[0];

      if (window.pageYOffset <= 10) {
        header.style.backgroundColor = '#000';
      } else {
        header.style.backgroundColor = '#fff';
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <div className="logo">
            {path === 'car_general_service' || path === 'submission_success' ? (
              // <img src={wisedDrivelogo} alt="company logo" className="company-logo" />
              <img
                src={WiseDriveLogoNew}
                alt="company logo"
                className="company-logo"
              />
            ) : (
              <Link to="/">
                <img
                  src={WiseDriveLogoNew}
                  alt="company logo"
                  className="company-logo"
                />
              </Link>
            )}

            <div className="menuLinkAndOwner">
              <div className="mobile">
                <div id="myNav" className={`overlay ${isOpen ? 'open' : ''}`}>
                  <ul>
                    <li>
                      <a onClick={openNav} href="#inspection-plans">
                        Inspection Plans
                      </a>
                    </li>
                    <li>
                      <a onClick={openNav} href="#how-it-works">
                        How it works
                      </a>
                    </li>
                    <li>
                      <Link onClick={openNav} to="/about-wisedrive">
                        About us
                      </Link>
                    </li>
                    <li>
                      <a onClick={openNav} href="#why-wisedrive">
                        ⁠⁠Why Wisedrive
                      </a>
                    </li>
                    <li>
                      <a onClick={openNav} href="#contact-us">
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
                <span onClick={openNav}>
                  <img src={Expand} alt="Expand" />
                </span>
              </div>
            </div>
          </div>

          <div className="navLinks">
            <ul>
              <li className="headerDropdownList"></li>
              <li>
                <a href="#inspection-plans">Inspection Plans</a>
              </li>
              <li>
                <a href="#how-it-works">How it works</a>
              </li>
              <li>
                <Link to="/about-wisedrive">About us</Link>
              </li>
              <li>
                <a href="#why-wisedrive">⁠⁠Why Wisedrive</a>
              </li>
              <li>
                <a href="#contact-us">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
